
.App {
  text-align: center;
}

.App-logo {
  height: 90vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-back-img{
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: -7;
  min-width: 1300px;
}

.header-logo {
    /* width: 14.5rem; */
    /* margin-right: -10px; */
    margin-top: -42px;
    margin-bottom: -50px;
    /* margin-left: -30px; */
}

.main-grid{
  display:grid;
  grid-template-areas:  'hs' 'ws' 'bbs' 'es' 'fs';
  grid-template-rows: auto 600px 200px 755px 50px;
}

.header-section{
  grid-area:hs;
}

.window-section{
  grid-area:ws;
  background: transparent;
  position: relative;
}

.bottom-banner-section{
  grid-area:bbs;
  background-color: #2c452e;
}

.bottom-banner-details{
  padding: 40px;
}

.extended-section{
  grid-area: es;
  background-color: white;
  padding: 115px;
  text-align: left;
}

.footer-section {
  grid-area: fs;
  background-color:darkgray;
  position: relative;
}

.action-icons{
  z-index: 2;
  position: fixed;
  bottom:0;
  right: 0;
}

.action-icon{
  position: absolute;
  right: 70px;
  bottom: 55px;
}

.header-bar{
  position: absolute;
  z-index: 4;
  width: 100%;
}

.window-banner-1{
  position: absolute;
  right: 100px;
  bottom: 100px;
  height: 60px;
  width: 300px;
}

.bullets{
  position: absolute;
  left: 100px;
  top: 150px;
  height: auto;
  width: 300px;
  padding: 15px
}

/* instagram link, etc */
i.fa { 
  font-size: 3.25em;
  color: #30b70f;
}

.footer-section > a{
  position: absolute;
  right: 15px;
  top: -55px;
  z-index:20;
}

@media (max-width: 525px) {
  .window-banner-1{
    position: absolute;
    bottom: 100px;
    height: 60px;
    width: 300px;
  }
  
  .bullets{
    position: absolute;
    top: 150px;
    height: auto;
    width: 300px;
    padding: 15px
  }

  .window-banner-1, .bullets{
    left: 0;
    right: 0;
    margin: auto;
  }

  .extended-section{
    grid-area: es;
    background-color: white;
    padding: 40px;
    text-align: left;
  }

}
